import React, { useState, useEffect } from 'react';
//MUI Stuff
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles, withStyles } from '@material-ui/core/styles';
// New Relic Monitor Data
import newRelicMonitors from '../NewRelic';
// Anant Logo
// import logo from '../Assets/Anant-logo-vertical.png';

function NewRelicCards() {
    let [monitors, setMonitorData] = useState({ monitors: [] });    

    useEffect(() => {
        const fetchMonitors = async () => {
            try {
                const leavesMonitorResults = await newRelicMonitors.getLeavesStatus();
                const leavesSearchMonitorResults = await newRelicMonitors.getLeavesSearchStatus();
                const leavesReaderMonitorResults = await newRelicMonitors.getLeavesReaderStatus();
                const leavesDevMonitorResults = await newRelicMonitors.getLeavesDevStatus();
                const leavesStageMonitorResults = await newRelicMonitors.getLeavesStageStatus();
                const leavesAdminMonitorResults = await newRelicMonitors.getLeavesAdminStatus();

                let monitors = [
                    leavesMonitorResults.data.actor.entity,
                    leavesSearchMonitorResults.data.actor.entity,
                    leavesReaderMonitorResults.data.actor.entity,
                    leavesDevMonitorResults.data.actor.entity,
                    leavesStageMonitorResults.data.actor.entity,
                    leavesAdminMonitorResults.data.actor.entity
                ]

                setMonitorData(monitors)

            } catch (error) {
                console.error(error.message);
            }
        }

        fetchMonitors();
    }, []);

    const GreenTextTypography = withStyles({
        root: {
          color: "#4caf50"
        }
      })(Typography);

      const RedTextTypography = withStyles({
        root: {
          color: "#e53935"
        }
      })(Typography);


    const useStyles = makeStyles(theme => ({
        cardGrid: {
            paddingTop: theme.spacing(8),
            paddingBottom: theme.spacing(8),
        },
        card: {
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
        },
        cardMedia: {
            paddingTop: '56.25%',
        },
        cardContent: {
            flexGrow: 1,
            textAlign: 'center'
        },
        root: {
            justifyContent: 'center'
        },
        HeaderLink: {
            padding: theme.spacing(1, 1, 1, 7)
        },
        footer: {
            backgroundColor: theme.palette.background.paper,
            padding: theme.spacing(6),
        },
    }));

    const classes = useStyles();

    return (
        <React.Fragment>
            {(monitors[0]) && monitors.map(card => (
                <Grid item key={card.name} xs={12} sm={6} md={4}>
                    <Card className={classes.card}>
                        <CardContent className={classes.cardContent}>
                            {card.monitorSummary.successRate === 1 ?
                                <GreenTextTypography variant="h5" component="h2" color="primary">
                                    100% Success Rate
                                </GreenTextTypography>
                                :
                                <RedTextTypography>
                                    Locations Failing {card.monitorSummary.locationsFailing}
                                </RedTextTypography>
                            }
                            <CardMedia className={classes.cardMedia}
                                image="none"
                                title="Site Image" />
                            <Typography variant="h5" component="h2">
                                {card.name}
                            </Typography>
                            <Typography variant="subtitle1" component="h2">
                                {card.monitoredUrl}
                            </Typography>
                        </CardContent>
                        <CardActions className={classes.root}>
                            <Button size="small" color="primary">
                                <a href={card.monitoredUrl}>View Deploys</a>
                            </Button>
                        </CardActions>
                    </Card>
                </Grid>
            ))}
        </React.Fragment>
    );
}

export default NewRelicCards;