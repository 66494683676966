import React, { Component } from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,  
} from "react-router-dom";
//Pages
import External from './Components/External';
import Internal from "./Components/Internal";
//CSS
import './App.css'

export class App extends Component {
  render() {
    return (
      <Router>
        <Switch>
          <Route exact path="/">
            <Internal />
          </Route>
          <Route exact path="/external">
            <External />
          </Route>
        </Switch>
      </Router>
    )
  }
}

export default App