const newRelicApiServer = 'https://fast-beach-17298.herokuapp.com';

const newRelicMonitors = {

    getLeavesStatus() {        
        return fetch(`${newRelicApiServer}/leaves-status`).then(res => res.json()).then((data) => {return data});
    },

    getLeavesSearchStatus() {
        return fetch(`${newRelicApiServer}/leaves-search`).then(res => res.json()).then((data) => {return data});
    },

    getLeavesReaderStatus() {
        return fetch(`${newRelicApiServer}/leaves-reader`).then(res => res.json()).then((data) => {return data});
    },

    getLeavesDevStatus() {
        return fetch(`${newRelicApiServer}/leaves-dev`).then(res => res.json()).then((data) => {return data});
    },

    getLeavesStageStatus() {
        return fetch(`${newRelicApiServer}/leaves-stage`).then(res => res.json()).then((data) => {return data});
    },

    getLeavesAdminStatus() {
        return fetch(`${newRelicApiServer}/leaves-admin`).then(res => res.json()).then((data) => {return data});
    },
};

export default newRelicMonitors;