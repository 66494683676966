import React from 'react';
//MUI Stuff
import AppBar from '@material-ui/core/AppBar';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Link from '@material-ui/core/Link';
//Status Page Data
import statusPageData from '../Data';

function External() {

  const useStyles = makeStyles(theme => ({
    cardGrid: {
      paddingTop: theme.spacing(8),
      paddingBottom: theme.spacing(8),
    },
    card: {
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
    },
    cardMedia: {
      paddingTop: '56.25%',
    },
    cardContent: {
      flexGrow: 1,
      textAlign: 'center'
    },
    root: {
      justifyContent: 'center'
    },
    internal: {
      padding: theme.spacing(1, 1, 1, 7)
    },
    footer: {
      backgroundColor: theme.palette.background.paper,
      padding: theme.spacing(6),
    },
  }));

  const statusPages = statusPageData.pages;

  const classes = useStyles();

  return (
    <React.Fragment>
      <CssBaseline />
      <AppBar position="relative">
        <Toolbar>
          <Typography variant="h6" color="inherit" noWrap>
            Status Page
          </Typography>
          <Typography className={classes.internal} variant="h6">
          <Link color="inherit" href="/">Internal Systems</Link>
        </Typography>
        <Typography className={classes.internal} variant="h6">
          <Link color="inherit" href="/external">External Systems</Link>
        </Typography>
        </Toolbar>
      </AppBar>
      <main>
        <Container className={classes.cardGrid} maxWidth="md">
          <Grid container spacing={4}>
            {statusPages.map(card => (
              <Grid item key={card.name} xs={12} sm={6} md={4}>
                <Card className={classes.card}>
                  <CardMedia
                    className={classes.cardMedia}
                    image={card.image}
                    title="Site Image"
                  />
                  <CardContent className={classes.cardContent}>
                    <Typography variant="h5" component="h2">
                      {card.name}
                    </Typography>
                  </CardContent>
                  <CardActions className={classes.root}>
                    <Button size="small" color="primary">
                      <a href={card.url}>View Status</a>
                    </Button>
                  </CardActions>
                </Card>
              </Grid>
            ))}
          </Grid>
        </Container>
      </main>
      <footer className={classes.footer}>
        <Typography variant="h6" align="center" gutterBottom>
          <Link color="inherit" href="https://anant.co">Anant.co</Link>
        </Typography>
        <Typography variant="subtitle1" align="center" color="textSecondary" component="p">
          © 2020 Anant Corporation. All rights reserved.
            </Typography>
      </footer>
    </React.Fragment>
  );
}

export default External;